import { mockCase } from "../entities/mocks/caseData"

export const portfolioInitialState = {
  caseIsVisible: false,
  caseData: mockCase,
  intervalId: null,
  mousePressTimeoutId: null,
  intervalIsRunning: false,
  intervalIsHalted: false,
  blockClick: false,
  caseLength: 3,
  temporaryDisabledCardClick: false,
  activeCard: null,
  lastDraggedIndex: null,
  portfolio: null,
  activeArtist: {
    name: 'ArtistName',
    rank: null
  },
  currentSlideNumber: 0,
  currentSlideArrayNumber: 0,
  caseDuration: 5000
};

export const portfolioActions = {
  temporaryDisableCardClick: () => ({
    temporaryDisabledCardClick: true
  }),
  enableCardClick: () => {
    return {temporaryDisabledCardClick: false}
  },
  showCase: (state, payload) => {
    return {
    caseIsVisible: !state.temporaryDisabledCardClick,
    caseData: payload,
    caseLength: payload.case.length
  }},
  hideCase: () => ({
    caseIsVisible: false
  }),
  setPortfolio: (state, payload) => ({
    portfolio: payload
  }),
  setCaseDuration: (state, payload) => ({
    caseDuration: payload
  }),
  setLastDraggedIndex: (state ,payload) => ({
    lastDraggedIndex: payload
  })
};