import React, { createContext, useReducer, useContext } from "react";
import { frontPageInitialState, frontPageActions } from "./frontPageActions";
import {portfolioInitialState, portfolioActions} from "./portfolioActions";
import {spoonInitialState, spoonActions} from "./spoonActions";


// we'll leave this empty for now
const initialState = {
  ...frontPageInitialState,
  ...portfolioInitialState,
  ...spoonInitialState
}

const StoreContext = createContext(initialState);

// this will act as a map of actions that will trigger state mutations
const Actions = {
  ...frontPageActions,
  ...portfolioActions,
  ...spoonActions
};

// the reducer is called whenever a dispatch action is made.
// the action.type is a string which maps to a function in Actions.
// We apply the update to existing state, and return a new copy of state.
const reducer = (state, action) => {
  const act = Actions[action.type];
  const update = act(state, action.payload);
  return { ...state, ...update};
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

// Store will be used in React components to fetch and mutate state
export const Store = () => {
  const { state, dispatch } = useContext(StoreContext);
  return { state, dispatch };
};

