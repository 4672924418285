/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { StoreProvider } from "./src/store/Store"
import { MobileDetectionContextProvider } from "./src/store/mobileDetection"
import "./src/styles/foundation.sass"

export const wrapRootElement = ({ element }) => {
  let isMobile, innerHeight, isSafari

  // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
  const isTouchDevice = () => {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    const mq = function (query) {
      return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }

  const checkSafari = () => {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1
  }

  const isDevice = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
      return (isDevice.Android() || isDevice.BlackBerry() || isDevice.iOS() || isDevice.Opera() || isDevice.Windows());
    }
  };

  if(typeof window !== 'undefined') {
    innerHeight = window.innerHeight
    isSafari = checkSafari()
    if(isDevice.any() && isTouchDevice()) {
      isMobile = true
    } else {
      isMobile = false
    }
  }



  return (
    <MobileDetectionContextProvider value={{isMobile, isSafari, innerHeight}}>
      <StoreProvider>{element}</StoreProvider>
    </MobileDetectionContextProvider>
  )
}