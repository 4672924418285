export const mockCase = {
  "name": "Faber",
  "position": 1,
  "id": "DatoCmsPortfolio-1931705-en",
  "case": [
    {
      "__typename": "DatoCmsBackgroundVideoSlide",
      "redBackground": true,
      "sandBackground": false,
      "videoDesktop": null,
      "pictureDesktop": {
        "fluid": {
          "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wEEEAAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGKEQAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGK/8IAEQgAGAAYAwEiAAIRAQMRAf/EABYAAQEBAAAAAAAAAAAAAAAAAAcGBP/aAAgBAQAAAACIu43e/DG1VJv/xAAVAQEBAAAAAAAAAAAAAAAAAAADAf/aAAgBAhAAAAAGG//EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oACAEDEAAAAEnRP//EACYQAAIBAgUEAgMAAAAAAAAAAAECAwARBAUGEjETIUFRFZEycYH/2gAIAQEAAT8AlzlJsAYEy6MMVEYkX8t31yabTOIlyKPDdJUnTaxBPn1ceSKw0C4eTFRNC8MyRkON5Nx74tatBZck+YvK63ECbl9bibA/yukoDEck3Na93QzQYiILeRGiYkA9h3HNaHndcDIGbsJSoH0e9Od0TDdYkcitbofjku25knBJ/Ytx4r//xAAeEQACAQMFAAAAAAAAAAAAAAAAARECAxMhMUFCcf/aAAgBAgEBPwDGtI4cicoo7FrZ+n//xAAcEQACAgIDAAAAAAAAAAAAAAAAAQMRAhIxUXH/2gAIAQMBAT8Ac2TvbqhqmTKskScrw//Z",
          "aspectRatio": 1,
          "src": "https://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&fm=jpg",
          "srcSet": "https://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=0.13&fm=jpg&w=1200 150w,\nhttps://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=0.25&fm=jpg&w=1200 300w,\nhttps://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=0.5&fm=jpg&w=1200 600w,\nhttps://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=0.75&fm=jpg&w=1200 900w,\nhttps://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=1&fm=jpg&w=1200 1200w",
          "sizes": "(max-width: 600px) 100vw, 600px"
        }
      },
      "text1Node": {
        "childMarkdownRemark": {
          "html": "<h1>What does chansonier mean in 2020?</h1>"
        }
      }
    },
    {
      "__typename": "DatoCmsBackgroundVideoSlide",
      "redBackground": false,
      "sandBackground": false,
      "videoDesktop": {
        "url": "https://www.datocms-assets.com/17782/1576082021-secondtrynotweb.mp4"
      },
      "pictureDesktop": null,
      "text1Node": {
        "childMarkdownRemark": {
          "html": "<h1>So cool it hurts</h1>"
        }
      },
      "text2Node": {
        "childMarkdownRemark": {
          "html": "<h1>Another remark</h1>"
        }
      }
    },
    {
      "__typename": "DatoCmsBackgroundVideoSlide",
      "redBackground": true,
      "sandBackground": false,
      "videoDesktop": null,
      "pictureDesktop": null,
      "text1Node": {
        "childMarkdownRemark": {
          "html": "<p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>"
        }
      }
    },
    {
      "__typename": "DatoCmsBackgroundVideoSlide",
      "redBackground": false,
      "sandBackground": true,
      "videoDesktop": null,
      "pictureDesktop": {
        "fluid": {
          "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wEEEAAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGKEQAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGK/8IAEQgAGAAYAwEiAAIRAQMRAf/EABYAAQEBAAAAAAAAAAAAAAAAAAcGBP/aAAgBAQAAAACIu43e/DG1VJv/xAAVAQEBAAAAAAAAAAAAAAAAAAADAf/aAAgBAhAAAAAGG//EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oACAEDEAAAAEnRP//EACYQAAIBAgUEAgMAAAAAAAAAAAECAwARBAUGEjETIUFRFZEycYH/2gAIAQEAAT8AlzlJsAYEy6MMVEYkX8t31yabTOIlyKPDdJUnTaxBPn1ceSKw0C4eTFRNC8MyRkON5Nx74tatBZck+YvK63ECbl9bibA/yukoDEck3Na93QzQYiILeRGiYkA9h3HNaHndcDIGbsJSoH0e9Od0TDdYkcitbofjku25knBJ/Ytx4r//xAAeEQACAQMFAAAAAAAAAAAAAAAAARECAxMhMUFCcf/aAAgBAgEBPwDGtI4cicoo7FrZ+n//xAAcEQACAgIDAAAAAAAAAAAAAAAAAQMRAhIxUXH/2gAIAQMBAT8Ac2TvbqhqmTKskScrw//Z",
          "aspectRatio": 1,
          "src": "https://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&fm=jpg",
          "srcSet": "https://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=0.13&fm=jpg&w=1200 150w,\nhttps://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=0.25&fm=jpg&w=1200 300w,\nhttps://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=0.5&fm=jpg&w=1200 600w,\nhttps://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=0.75&fm=jpg&w=1200 900w,\nhttps://www.datocms-assets.com/17782/1576239635-81rno10cpnl-sl1200.jpg?auto=compress%2Cformat&dpr=1&fm=jpg&w=1200 1200w",
          "sizes": "(max-width: 600px) 100vw, 600px"
        }
      },
      "text1Node": {
        "childMarkdownRemark": {
          "html": "<h1>So Cool,So Fresh</h1>"
        }
      }
    }
  ]
}