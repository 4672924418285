export const frontPageInitialState = {
  isScrolling: false,
  activeSnapping: true,
  mobileMenuIsOpen: false,
  spoonIsShutDown: false,
  pentagramIsHidden: true,
  manifestoPageTwo: false,
  cardAnimationIsRunning: false,
  overflowHidden: false,
  cardAnimationData: null,
  previewTimer: null,
  windowSize: {
    width: null,
    height: null,
    isMobile: false
  }
};

export const frontPageActions = {
  deactivateSnapping: () => ({ activeSnapping: false}),
  activateSnapping: () => ({ activeSnapping: true}),
  openMobileMenu: () => ({mobileMenuIsOpen: true}),
  closeMobileMenu: () => ({mobileMenuIsOpen: false}),
  showManifestoPageTwo: () => ({manifestoPageTwo: true}),
  showManifestoPageOne: () => ({manifestoPageTwo: false}),
  startCardAnimation: (state, payload) => ({cardAnimationIsRunning: true, cardAnimationData: payload}),
  stopCardAnimation: () => ({cardAnimationIsRunning: false}),
  hideOverflow:  () => ({overflowHidden: true}),
  enableOverflow:  () => ({overflowHidden: false}),
  previewTimer: (state, payload) => {
    return ({
      previewTimer: payload
    })
  },
  setWindowSize: (state, payload) => {
    const isMobile = payload.width < 480
    return ({
      windowSize: {
        width: payload.width,
        height: payload.height,
        isMobile: isMobile
      }
    })
  }
};