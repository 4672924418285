export const spoonInitialState = {
  spoon: {
    mount: null,
    spoon: null,
    scene: null,
    renderer: null,
    bend: null,
    camera: null,
    width: 400,
    height: 400,
    devicePixelRatio: 1,
    objectIsLoaded: false,
    isTouchBending: false
  }
};

export const spoonActions = {
  setMount: (state, payload) => { state.spoon.mount = payload},
  setSpoon: (state, payload) => { state.spoon.spoon = payload},
  setRenderer: (state, payload) => { state.spoon.renderer = payload},
  startTouchBend: (state) => { state.spoon.isTouchBending = true },
  rewindTouchBend: (state) => { state.spoon.isTouchBending = false},
  setScene: (state, payload) => { state.spoon.scene = payload},
  setCamera: (state, payload) => { state.spoon.camera = payload},
  setBend: (state, payload) => { state.spoon.bend = payload},
  setModifierStack: (state, payload) => { state.spoon.modifierStack = payload},
  setAllFour: (state, payload) => {
    state.spoon.renderer = payload.renderer
    state.spoon.scene = payload.scene
    state.spoon.camera = payload.camera
    state.spoon.bend = payload.bend
  },
  setWidth: (state, payload) => { state.spoon.width = payload},
  setHeight: (state, payload) => { state.spoon.height = payload},
  setDevicePixelRatio: (state, payload) => { state.spoon.devicePixelRatio = payload},
  loadedObject: (state) => {state.spoon.objectIsLoaded = true}
};