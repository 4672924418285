import React, { useContext } from "react"

export const MobileDetectionContext = React.createContext({isMobile: false, innerHeight: 1080, isSafari: false});
export const MobileDetectionContextProvider = MobileDetectionContext.Provider
export const MobileDetectionContextConsumer = MobileDetectionContext.Consumer

// Store will be used in React components to fetch and mutate state
export const MobileDetection = () => {
  const { isMobile, innerHeight, isSafari } = useContext(MobileDetectionContext);
  return {isMobile, innerHeight, isSafari}
};